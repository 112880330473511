import React, { useState } from 'react';

import {
  Button,
  Grid,
  Typography,
  makeStyles,
  useMediaQuery,
  Container,
} from '@material-ui/core';
import { TableSection } from './TableSection';
import { MobileFeatures } from './Mobile/MobileFeatures';

const useStyles = makeStyles((theme) => ({
  introContainer: {
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
    },
  },
  header: {
    fontWeight: 600,
    marginLeft: '1rem',
    color: 'white',
  },
  sectionHeader: {
    fontWeight: 700,
    color: theme.workwaveBlue,
  },
  subHeader: {
    color: theme.workwaveBlue,
  },
  button: {
    cursor: 'pointer',
    opacity: 1,
    textDecoration: 'none',
    transition: 'all .5s',
    '&:hover': {
      opacity: 0.8,
    },
  },
  expandText: {
    cursor: 'pointer',
    textAlign: 'center',
    paddingBottom: '4rem',
    fontWeight: 600,
    opacity: 1,
    transition: 'all .5s',
    paddingTop: '2rem',
    '&:hover': {
      opacity: 0.8,
    },
  },
  compareTitleCell: {
    borderTopLeftRadius: '22px',
    borderTopRightRadius: '22px',
    borderColor: '#ffffff !important',
    borderWidth: 0,
    borderStyle: 'solid',
  },
  subtitleText: {
    color: '#5A7184',
    fontSize: '0.9375rem',
    marginTop: '10px',
  },
  otherCompareLink: {
    textDecoration: 'none',
    color: theme.workwaveBlue,
    '&:hover': {
      '& $arrow': { transform: 'translateX(10px)' },
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.5rem',
    },
  },
  arrow: {
    marginLeft: '.25rem',
    transition: 'transform .25s ease-in-out',
    display: 'inline-block',
  },
  button: {
    margin: '0 10px',
    [theme.breakpoints.down('sm')]: {
      margin: '0 5px',
      marginBottom: '16px',

    },
    '&.MuiButton-label': {
      [theme.breakpoints.down('sm')]: {
        fontSize: '.8rem',
      },
    },
  },
}));

export const SoftwareFeatures = ({ softwareFeatures }) => {
  const [selectedTable, setSelectedTable] = useState(0);
  const classes = useStyles();

  const md = useMediaQuery('(max-width: 960px)');

  return (
    <>
      <Container>
        <Grid
          container
          direction='column'
          justifyContent='center'
          alignContent='center'
          spacing={2}
          className={classes.introContainer}
        >
          <Grid item xs={12} md={10} lg={8}>
            <Typography variant='h2' className={classes.sectionHeader}>
              TEAM Software vs. Competitors
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          direction='row'
          justifyContent='center'
          alignItems='center'
          spacing={2}
          style={{ padding: '2rem 0' }}
        >
          {softwareFeatures.map((feature, index) => (
            <Button
              key={index}
              color={index === selectedTable ? 'primary' : 'default'}
              variant={index === selectedTable ? 'contained' : 'outlined'}
              onClick={(e) => setSelectedTable(index)}
              className={classes.button}
              size='large'
             
            >
              {feature.comparisonHeader}
            </Button>
          ))}
        </Grid>
      </Container>
      {softwareFeatures.map((feature, index) =>
        index === selectedTable ? (
          md ? (
            <MobileFeatures
              key={index}
              softwareFeatures={feature.compareFeatures}
              logos={feature.logos}
            />
          ) : (
            <TableSection
              key={index}
              softwareFeatures={feature.compareFeatures}
              logos={feature.logos}
            />
          )
        ) : null
      )}
    </>
  );
};
