import React, { useContext, useEffect } from 'react';
import { graphql } from 'gatsby';

import { useMediaQuery } from '@material-ui/core';

import { IntroCards } from '../components/Home/Intro/IntroCards';
import { Testimonials } from '../components/Testimonials';
import { CustomerLogos } from '../components/CustomerLogos';
import { Resources } from '../components/General/Resources';
import { SectionBlue } from '../components/Sections/SectionBlue';
import { SectionWhite } from '../components/Sections/SectionWhite';
import { SectionLight } from '../components/Sections/SectionLight';
import { HeroWaveTwo } from '../components/Waves/HeroWaveTwo';
import { SectionWaveDefault } from '../components/Waves/SectionWaveDefault';
import { GeneralHero } from '../components/General/GeneralHero';
import { SEO } from '../components/SEO';

import LocaleContext from '../context/LocaleContext';
import Form from '../components/ContactForm/Form';
import { SoftwareFeatures } from '../components/Compare/SoftwareFeatures';

const ComparePage = ({ data: { compare }, location }) => {
  const { handleRedirect, ipData } = useContext(LocaleContext);

  const {
    _rawIntroText,
    _rawCustomerLogosHeader,
    customerLogos,
    testimonialHeader,
    testimonials,
    resourceTitle,
    resources,
    _rawResourceBody,
    hero,
    introCards,
    contactForm,
    pardotUrl,
    softwareFeatures,
    metaTitle,
    metaDescription,
  } = compare;

  useEffect(() => {
    ipData && handleRedirect(location.pathname, true);
  }, [ipData]);

  const md = useMediaQuery('(max-width: 960px)');
  const video = testimonials.length ? testimonials[0]?.videoVariant : null;

  return (
    <>
      <SEO title={metaTitle} description={metaDescription} />

      <SectionBlue top extraBottom>
        <GeneralHero heroData={hero} />
        <HeroWaveTwo />
      </SectionBlue>
      <SectionWhite marginBottom='-90px'>
        <IntroCards intro={_rawIntroText} introCards={introCards} compare />
      </SectionWhite>
      <SectionBlue
        top
        bottom={!md}
        additionalBottom={!md && 40}
        extraBottom={md}
      >
        <SectionWaveDefault
          flipHorizontal={true}
          flipVertical={true}
          bottom='-1px'
          fillColorShape='#FFF'
        />
      </SectionBlue>
      <SoftwareFeatures softwareFeatures={softwareFeatures} />
      <SectionBlue
        extraTop={!video}
        top={video}
        additionalTop={video && md ? 60 : video ? 80 : null}
        marginBottom='-220px'
      >
        <SectionWaveDefault top='0px' fillColorShape='#FFF' />
        <Testimonials
          testimonials={testimonials}
          header={testimonialHeader}
          background
        />
      </SectionBlue>
      {customerLogos && (
        <SectionWhite top additionalTop={220} extraBottom>
          <CustomerLogos
            customerLogosArray={customerLogos}
            customerLogosHeader={_rawCustomerLogosHeader}
          />
          <SectionWaveDefault
            flipHorizontal={false}
            flipVertical={true}
            bottom='0px'
            fillColorShape='#F5F9FF'
            fillColorBorder='#F5F9FF'
          />
        </SectionWhite>
      )}
      <SectionLight top={md} bottom={md}>
        <Form
          pardotUrl={pardotUrl}
          contactForm={contactForm}
          location={location}
        />
      </SectionLight>
      <SectionWhite top additionalTop={100} additionalBottom={100} zIndex={3}>
        <SectionWaveDefault
          top='0px'
          fillColorBorder='#F5F9FF'
          flipHorizontal={true}
        />
        <Resources
          header={resourceTitle}
          subheader={_rawResourceBody}
          resources={resources}
        />
      </SectionWhite>
    </>
  );
};

export default ComparePage;

export const query = graphql`
  query ($slug: String!) {
    compare: sanityCompare(slug: { current: { eq: $slug } }) {
      metaTitle
      metaDescription
      _type
      id
      slug {
        current
      }
      language
      leadoo {
        botId
        seamless
      }
      hero {
        _rawContent
        heroImage {
          asset {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        showEmailForm
      }
      _rawIntroText
      introCards {
        icon
        header
        description
      }
      softwareFeatures {
        title
        comparisonHeader
        featureListCta
        logos {
          asset {
            gatsbyImageData
          }
        }
        alternateTextHeaders
        compareFeatures {
          sectionHeader
          features {
            featureTitle
            colOne
            colTwo
          }
        }
        expandedListHeader
        expandedListSubheader
        expandText
        hideText
      }
      _rawCustomerLogosHeader
      customerLogos {
        logo {
          asset {
            gatsbyImageData(placeholder: BLURRED, fit: FILLMAX)
          }
        }
        title
      }

      testimonialHeader
      testimonials {
        title
        testimonialText
        nameAndTitle
        videoVariant
        company
        image {
          asset {
            gatsbyImageData(placeholder: BLURRED, width: 700)
          }
        }
      }
      contactForm {
        header
        privacyPolicy
      }
      pardotUrl
      resourceTitle
      _rawResourceBody
      resources {
        title
        blurb
        image {
          asset {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        ctaText
        ctaLink
      }
    }
  }
`;
