import React, { useState, useContext } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import {
  Grid,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Box,
  makeStyles,
  Container,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import FormModalContext from '../../../../context/FormModalContext';

// New component: Font Awesome SVG checkmark
const FAIconCheck = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
    width="16"
    height="16"
    className={className}
    style={{ display: 'inline-block', verticalAlign: 'middle' }}
  >
    <path
      fill="currentColor"
      d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 
         12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 
         45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
    />
  </svg>
);

const useStyles = makeStyles((theme) => ({
  pillTabs: {
    '& .MuiTab-root': {
      borderRadius: '9999px',
      padding: theme.spacing(1, 2),
    },
  },
  accordion: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    borderRadius: 0,
  },
  accordionSummary: {
    padding: 0,
    margin: 0,
    fontWeight: 600,
    '&.Mui-expanded': {
      minHeight: 0,
      margin: 0,
    },
  },
  accordionSummaryContent: {
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    '&.Mui-expanded': {
      margin: 0,
    },
  },
  accordionDetails: {
    padding: 0,
    '&.Mui-expanded': {
      margin: 0,
    },
  },
  gridItem: {
    backgroundColor: '#ffffff',
    border: `2px solid #C3CAD9`,
    borderBottom: 'none',
    borderRadius: 0,
    padding: theme.spacing(2),
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    boxSizing: 'border-box',
    '&.withBottomBorder': {
      borderBottom: `2px solid #C3CAD9`,
    },
    '&.noTopBorder': {
      borderTop: 'none',
    },
  },
  gridItemBlue: {
    backgroundColor: '#047FB9',
    color: '#fff',
    border: `1px solid #C3CAD9`,
    borderBottom: 'none',
    borderRadius: 0,
    padding: theme.spacing(2),
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    boxSizing: 'border-box',
    position: 'relative',
  },
  gridSpacing: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  // New classes to remove left or right padding
  noLeft: {
    paddingLeft: 0,
  },
  noRight: {
    paddingRight: 0,
  },
  // New class for checkmark color
  checkIcon: {
    color: '#0380B6',
  },
  expandIcon: {
    position: 'absolute',
    right: theme.spacing(1),
    top: '50%',
    fontWeight: 600,
    transform: 'translateY(-50%)',
    transition: 'transform 0.2s ease',
    '&.expanded': {
      transform: 'translateY(-50%) rotate(180deg)',
    },
  },
  firstColWithIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dynamicBorder: {
    borderBottom: `1px solid #C3CAD9`,
  },
  featureTitle: {
    fontWeight: 600,
    fontSize: '1.2rem',
    lineHeight: 1.2,
    letterSpacing: '.2px',
    width: '100%',
  },
  cta: {
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8,
    },
  },
  accordionExpanded: {
    minHeight: 0,
    margin: 0,
    '&.Mui-expanded': {
      margin: 0,
    },
  },
  accordionContent: {
    minHeight: 0,
    margin: 0,
    '&.Mui-expanded': {
      margin: 0,
    },
  },
  header: {
    border: `2px solid #C3CAD9`,
    borderRadius: '20px 20px 0 0',
    padding: '15px',
    height: '84px',
    width: '100%',
    textAlign: 'center',
  },
}));

export const MobileFeatures = ({ softwareFeatures, logos }) => {
  const classes = useStyles();
  const { handleModalClick } = useContext(FormModalContext);

  const [expandedAccordions, setExpandedAccordions] = useState([0]);

  const handleAccordionChange = (index) => (_, isExpanded) => {
    setExpandedAccordions((prev) =>
      isExpanded ? [...prev, index] : prev.filter((i) => i !== index)
    );
  };

  // Determine if the last accordion is open for conditional styling
  const isLastAccordionOpen = expandedAccordions.includes(
    softwareFeatures.length - 1
  );

  return (
    <>
      <Container>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          className={classes.header}
        >
          <Typography variant="h5" color="primary" style={{ fontWeight: 700 }}>
            TEAM vs. Competitor
          </Typography>
        </Grid>
      </Container>

      <Box>
        {softwareFeatures.map((feature, index) => (
          <Container key={index}>
            <Accordion
              className={classes.accordion}
              disableGutters
              expanded={expandedAccordions.includes(index)}
              onChange={handleAccordionChange(index)}
            >
              <AccordionSummary
                classes={{
                  content: classes.accordionContent,
                  expanded: classes.accordionExpanded,
                  root: classes.accordionSummary,
                }}
              >
                <Grid container className={classes.gridItemBlue}>
                  <Grid item xs={10}>
                    <Typography variant="h6">
                      {feature.sectionHeader}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton style={{ color: 'white' }}>
                      {expandedAccordions.includes(index) ? (
                        <RemoveIcon />
                      ) : (
                        <AddIcon />
                      )}
                    </IconButton>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails classes={{ root: classes.accordionDetails }}>
                <Grid container direction="column">
                  {feature.features.map((item, featureIndex) => (
                    <Grid container alignItems="stretch" key={featureIndex}>
                      {/* Left item: remove left padding */}
                      <Grid
                        item
                        xs={8}
                        sm={8}
                        className={`${classes.gridSpacing} ${classes.noLeft}`}
                      >
                        <div
                          className={`${classes.gridItem} ${
                            featureIndex === feature.features.length - 1
                              ? 'withBottomBorder'
                              : ''
                          }`}
                        >
                          <Typography
                            className={classes.featureTitle}
                            color="primary"
                          >
                            {item.featureTitle}
                          </Typography>
                        </div>
                      </Grid>
                      {/* Center item: standard spacing */}
                      <Grid item xs={2} sm={2} className={classes.gridSpacing}>
                        <div
                          className={`${classes.gridItem} ${
                            featureIndex === feature.features.length - 1
                              ? 'withBottomBorder'
                              : ''
                          }`}
                        >
                          <Typography>
                            {item.colOne ? (
                              <FAIconCheck className={classes.checkIcon} />
                            ) : (
                              '❌'
                            )}
                          </Typography>
                        </div>
                      </Grid>
                      {/* Right item: remove right padding */}
                      <Grid
                        item
                        xs={2}
                        sm={2}
                        className={`${classes.gridSpacing} ${classes.noRight}`}
                      >
                        <div
                          className={`${classes.gridItem} ${
                            featureIndex === feature.features.length - 1
                              ? 'withBottomBorder'
                              : ''
                          }`}
                        >
                          <Typography>
                            {item.colTwo ? (
                              <FAIconCheck className={classes.checkIcon} />
                            ) : (
                              '❌'
                            )}
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Container>
        ))}

        {/* CHAT WITH US Button */}
        <Container>
          <div
            className={`${classes.gridItem} withBottomBorder ${
              isLastAccordionOpen ? 'noTopBorder' : ''
            } ${classes.cta}`}
            style={{
              backgroundColor: '#002D5C',
              borderRadius: '0 0 20px 20px',
            }}
            onClick={handleModalClick}
          >
            <Typography style={{ color: '#fff', fontWeight: 600 }} variant="h5">
              CHAT WITH US
            </Typography>
          </div>
        </Container>
      </Box>
    </>
  );
};
