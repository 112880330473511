import React, { useState, useContext } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

import {
  Grid,
  Typography,
  makeStyles,
  Box,
  Container,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Button,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import FormModalContext from '../../../context/FormModalContext';

// New component for the Font Awesome SVG checkmark.
const FAIconCheck = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 448 512'
    width='20'
    height='20'
    style={{
      display: 'inline-block',
      verticalAlign: 'middle',
      color: '#047FB9',
    }}
  >
    <path
      fill='currentColor'
      d='M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 
      12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 
      45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z'
    />
  </svg>
);

const useStyles = makeStyles((theme) => ({
  pillTabs: {
    '& .MuiTab-root': {
      borderRadius: '9999px',
      padding: theme.spacing(1, 2),
    },
  },
  accordion: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    borderRadius: 0,
  },
  accordionSummary: {
    padding: 0,
    margin: 0,
    fontWeight: 600,
    '&.Mui-expanded': {
      minHeight: 0,
    },
  },
  accordionSummaryContent: {
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    '&.Mui-expanded': {
      margin: 0,
    },
  },
  accordionDetails: {
    padding: 0,
  },
  gridItem: {
    backgroundColor: '#ffffff',
    border: `2px solid #C3CAD9`,
    borderBottom: 'none',
    borderRadius: 0,
    padding: theme.spacing(2),
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    boxSizing: 'border-box',
    '&.withBottomBorder': {
      borderBottom: `2px solid #C3CAD9`,
    },
    '&.noTopBorder': {
      borderTop: 'none',
    },
  },
  gridItemBlue: {
    backgroundColor: '#047FB9',
    color: '#fff',
    border: `1px solid #C3CAD9`,
    borderBottom: 'none',
    borderRadius: 0,
    padding: theme.spacing(2),
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    boxSizing: 'border-box',
    position: 'relative',
  },
  gridSpacing: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  expandIcon: {
    position: 'absolute',
    right: theme.spacing(1),
    top: '50%',
    fontWeight: 600,
    transform: 'translateY(-50%)',
    transition: 'transform 0.2s ease',
    '&.expanded': {
      transform: 'translateY(-50%) rotate(180deg)',
    },
  },
  firstColWithIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dynamicBorder: {
    borderBottom: `1px solid #C3CAD9`,
  },
  featureTitle: {
    fontWeight: 600,
    fontSize: '1.2rem',
    lineHeight: 1.2,
    letterSpacing: '.2px',
    width: '100%',
  },
  cta: {
    height: '100%',
    width: '100%',
    '&:hover': {
      opacity: 0.8,
    },
  },
}));

export const TableSection = ({ softwareFeatures, logos }) => {
  const classes = useStyles();
  const { handleModalClick } = useContext(FormModalContext);

  const [expandedAccordions, setExpandedAccordions] = useState([0, 1, 2, 3]);

  const handleAccordionChange = (index) => (_, isExpanded) => {
    setExpandedAccordions((prev) =>
      isExpanded ? [...prev, index] : prev.filter((i) => i !== index)
    );
  };

  const isFirstAccordionClosed = !expandedAccordions.includes(0);
  const isLastAccordionOpen = expandedAccordions.includes(
    softwareFeatures.length - 1
  );

  return (
    <Box mt={3} className={classes.root}>
      {softwareFeatures.map((feature, index) => (
        <Container key={feature.sectionHeader}>
          <Accordion
            className={classes.accordion}
            disableGutters
            expanded={expandedAccordions.includes(index)}
            onChange={handleAccordionChange(index)}
          >
            <AccordionSummary
              classes={{
                root: classes.accordionSummary,
                content: classes.accordionSummaryContent,
              }}
            >
              <Grid container alignItems='stretch'>
                <Grid item xs={8} sm={8} className={classes.gridSpacing}>
                  <div
                    className={classes.gridItemBlue}
                    // Apply border top radius only for the first accordion (index === 0)
                    style={index === 0 ? { borderRadius: '20px 20px 0 0' } : {}}
                  >
                    <Typography
                      variant='h5'
                      style={{
                        color: '#fff',
                        fontWeight: 600,
                      }}
                    >
                      {feature.sectionHeader}
                    </Typography>
                    <IconButton
                      style={{
                        position: 'absolute',
                        right: 15,
                        color: 'white',
                      }}
                    >
                      {expandedAccordions.includes(index) ? (
                        <RemoveIcon />
                      ) : (
                        <AddIcon />
                      )}
                    </IconButton>
                  </div>
                </Grid>
                {logos.length && index === 0 && (
                  <>
                    <Grid item xs={2} sm={2} className={classes.gridSpacing}>
                      <div
                        className={`${classes.gridItem} ${
                          index === 0 && isFirstAccordionClosed
                            ? 'withBottomBorder'
                            : ''
                        }`}
                        // Reduce padding to 5px and apply top border radius
                        style={{
                          borderRadius: '20px 20px 0 0',
                          padding: '5px',
                        }}
                      >
                        <GatsbyImage
                          image={logos[0].asset.gatsbyImageData}
                          style={{ maxWidth: '50px' }}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={2} sm={2} className={classes.gridSpacing}>
                      <div
                        className={`${classes.gridItem} ${
                          index === 0 && isFirstAccordionClosed
                            ? 'withBottomBorder'
                            : ''
                        }`}
                        // Reduce padding to 5px and apply top border radius
                        style={{
                          borderRadius: '20px 20px 0 0',
                          padding: '5px',
                        }}
                      >
                        <Typography color='primary' style={{ fontWeight: 600 }}>
                          Competitor
                        </Typography>
                      </div>
                    </Grid>
                  </>
                )}
              </Grid>
            </AccordionSummary>

            <AccordionDetails classes={{ root: classes.accordionDetails }}>
              <Grid container direction='column'>
                {feature.features.map((item, featureIndex) => (
                  <Grid container alignItems='stretch' key={featureIndex}>
                    <Grid item xs={8} sm={8} className={classes.gridSpacing}>
                      <div
                        className={`${classes.gridItem} ${
                          featureIndex === feature.features.length - 1 &&
                          index === softwareFeatures.length - 1
                            ? 'withBottomBorder'
                            : ''
                        }`}
                      >
                        <Typography
                          className={classes.featureTitle}
                          color='primary'
                        >
                          {item.featureTitle}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={2} sm={2} className={classes.gridSpacing}>
                      <div
                        className={`${classes.gridItem} ${
                          featureIndex === feature.features.length - 1
                            ? 'withBottomBorder'
                            : ''
                        }`}
                      >
                        <Typography>
                          {item.colOne ? <FAIconCheck /> : '❌'}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={2} sm={2} className={classes.gridSpacing}>
                      <div
                        className={`${classes.gridItem} ${
                          featureIndex === feature.features.length - 1
                            ? 'withBottomBorder'
                            : ''
                        }`}
                      >
                        <Typography>
                          {item.colTwo ? <FAIconCheck /> : '❌'}
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Container>
      ))}
      {/* Final Content */}
      <Container style={{ marginTop: '1rem' }}>
        <Grid container alignItems='stretch'>
          <Grid item xs={8} sm={8} className={classes.gridSpacing}>
            <div
              className={`${classes.gridItem} withBottomBorder ${
                isLastAccordionOpen ? '' : ''
              }`}
              style={{ borderRadius: '0px' }} // Bottom border radius
            >
              <Typography
                style={{ textAlign: 'left', width: '100%' }}
                variant='h3'
                color='primary'
              >
                Ready to Learn More?
              </Typography>
            </div>
          </Grid>
          <Grid item xs={4} sm={4} className={classes.gridSpacing}>
            {/* <div
              className={`${classes.gridItem} withBottomBorder ${
                isLastAccordionOpen ? 'noTopBorder' : ''
              } ${classes.cta}`}
              style={{
                backgroundColor: '#002D5C',
                borderRadius: '0px', // Bottom border radius
              }}
              onClick={handleModalClick}
            >
              <Typography
                style={{ color: '#fff', fontWeight: 600 }}
                variant='h5'
              >
                CHAT WITH US
              </Typography>
            </div> */}
            <Button
              className={classes.cta}
              onClick={handleModalClick}
              color='primary'
              variant='contained'
            >
              CHAT WITH US
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
